import {Box} from '@mui/material'
import Container from '../../comps/Container'
import HowItWorks from './HowItWorks';
import Landing from './Landing';
import Trails from './Trails';

const HomePage = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Landing/>
        <HowItWorks/>
        <Trails/>
      </Container>
    </Box>
  )
}

export default HomePage;
