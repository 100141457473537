import React from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {enableAllPlugins} from 'immer'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import HomePage from './pages/home/HomePage';
import RedeemPage from './pages/redeem/RedeemPage';
import AppPage from "./pages/app/AppPage";
import StampPage from "./pages/app/StampPage";
import store from './state';
import initFirebase from './firebase';


// initialize immer
enableAllPlugins();

// initialize firebase
initFirebase(store);

// initialize mui theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#00C06A',
      contrastText: 'white'
    },
    secondary: {
      main: '#EEB902'
    },
    common: {
      black: '#363732'
    },
    background: {
      default: '#f7fafc'
    }
  },
  typography: {
    fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
  shadows: [
    'none',
    'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
  ],
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.MuiButton-root': {
          '&': {
            boxShadow: 'none !important',
            borderRadius: '0 !important'
          }
        }
      }
    }
  }
});

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/app" element={<AppPage />} />
            <Route path="/app/stamp/:stamp" element={<StampPage />} />
            <Route path="/redeem/:card" element={<RedeemPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  )
}
export default App;

