import { Box } from '@mui/material'

const Section = ({children, sx, ...props}) => {
  return (
    <Box {...props} sx={{
      py: {
        xs: 4, sm: 6, md: 8, lg: 12
      },
      ...sx
    }}>
      {children}
    </Box>
  )
}

export default Section;
