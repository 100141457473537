import {Box, Grid, Typography} from '@mui/material';
import Em from '../../comps/Em';
import Section from './Section';

const Trails = () => {
  return (
    <Section id='trails'>
      <Typography fontWeight={900} color="primary" textTransform="uppercase" textAlign="center" sx={{
        fontSize: {xs: '3rem', md: '3.75rem'},
      }}>
        The Trails
      </Typography>
      <Typography variant="body1" sx={{
        fontSize: '1.5rem',
      }}>
        All of the cones are located on the <Em>Mosquito Bite</Em>, <Em>Fun Forest</Em>, and <Em>Loki</Em> trails. Below you'll find
        links to Trailforks for more info about these trails.
      </Typography>
      <Box mt={2}>
      <Grid container spacing={1} flexWrap="wrap" justifyContent="center">
        <Grid item>
          <Typography fontWeight={700} color="primary" textTransform="uppercase" textAlign="center" sx={{
            fontSize: {xs: '1.5rem', md: '1.75rem'},
          }}>
            Mosquito Bite
          </Typography>
          <Box sx={{
            border: `2px solid`,
            borderColor: 'primary.main'
          }}>
            <iframe title="Mosquito Bite" width="512" height="350" style={{maxWidth: '100%'}}
                  src="https://www.trailforks.com/widgets/trail/?trailid=94963&w=512px&h=350px&activitytype=1&map=1&elevation=0&photos=0&title=0&info=0&trail_opacity=25&v=2"></iframe>
          </Box>
        </Grid>
        <Grid item>
          <Typography fontWeight={700} color="primary" textTransform="uppercase" textAlign="center"  sx={{
            fontSize: {xs: '1.5rem', md: '1.75rem'},
          }}>
            Fun Forest
          </Typography>
          <Box sx={{
            border: `2px solid`,
            borderColor: 'primary.main'
          }}>
            <iframe title="Fun Forest" width="512" height="350" style={{maxWidth: '100%'}}
                  src="https://www.trailforks.com/widgets/trail/?trailid=437549&w=512px&h=350px&activitytype=1&map=1&elevation=0&photos=0&title=0&info=0&trail_opacity=25&v=2"></iframe>
          </Box>
        </Grid>
        <Grid item>
          <Typography fontWeight={700} color="primary" textTransform="uppercase" textAlign="center" sx={{
            fontSize: {xs: '1.5rem', md: '1.75rem'},
          }}>
            Loki's
          </Typography>
          <Box sx={{
            border: `2px solid`,
            borderColor: 'primary.main'
          }}>
            <iframe title="Loki's" width="512" height="350" style={{maxWidth: '100%'}}
                    src="https://www.trailforks.com/widgets/trail/?trailid=28835&w=512&h=350px&activitytype=1&map=1&elevation=0&photos=0&title=0&info=0&trail_opacity=25&v=2"></iframe>
          </Box>
        </Grid>
      </Grid>
      </Box>
    </Section>
  )
}

export default Trails
