import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import Em from '../../comps/Em';

const RedeemCardModal = ({card, open, onClose}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Get your Ice Cream</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Show this QR code to one of the good people at the Old School Ice Cream Bus to redeem your free <Em>cone</Em>.
        </DialogContentText>
        <Box display="flex" justifyContent="center" mt={2}>
          <Box component="img" src={card.qrcode} alt="" sx={{
            borderColor: 'common.black',
            borderWidth: 4,
            borderStyle: 'solid',
            borderRadius: 2
          }}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RedeemCardModal;
