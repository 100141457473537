import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {IMaskInput} from 'react-imask';
import {getAuth, signInWithPhoneNumber, RecaptchaVerifier} from 'firebase/auth';
import {
  Box,
  Button, CircularProgress,
  Grid,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import {ReactComponent as Logo} from '../../assets/c4c-logo.svg';
import ErrorAlert from '../../comps/ErrorAlert';
import Container from '../../comps/Container';

const PhoneInput = forwardRef(({onChange, ...props}, ref) => {
  return (
    <IMaskInput
      {...props}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
})

const Auth = () => {
  const [recaptcha, setRecaptcha] = useState();
  const [callback, setCallback] = useState();
  const [error, setError] = useState();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [isWorking, setWorking] = useState(false);
  const ref = useRef();

  const auth = getAuth();
  const phoneIsOk = () => {
    return phone.match(/\(\d{3}\) \d{3}-\d{4}/);
  }

  useEffect(() => {
    if (ref.current) {
      setRecaptcha(new RecaptchaVerifier(ref.current, {
        size: 'invisible',
        callback: (response) => {
          // console.log(response);
        }
      }, auth));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const login = () => {
    setWorking(true);
    signInWithPhoneNumber(getAuth(), `+1${phone.replaceAll(/\W/g, '')}`, recaptcha)
      .then(setCallback)
      .catch(setError)
      .finally(() => setWorking(false))
  }

  const confirm = () => {
    setWorking(true);
    callback.confirm(code)
      .then(console.log)
      .catch(setError)
      .finally(() => setWorking(false))
  };

  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center">
        <SvgIcon component={Logo} inheritViewBox sx={{width: '100%', height: 'auto', maxWidth: '728px', margin: 'auto'}}/>
        {error && <ErrorAlert error={error}/>}
        {!error &&
          <Zoom in={!callback} appear={false} unmountOnExit={true}>
            <Box width="100%" px={1}>
              <Typography variant="h6" sx={{my: 4}} textAlign="center">To get started, enter your phone number.</Typography>
              <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={8} md={4}>
                  <OutlinedInput name="phoneNumber" type="tel" variant="outlined" fullWidth={true}
                                 placeholder="(555) 555-5555"
                                 inputComponent={PhoneInput}
                                 startAdornment={<InputAdornment position="start">+1</InputAdornment>}
                                 value={phone}
                                 onChange={(evt) =>  setPhone(evt.target.value)}>
                  </OutlinedInput>
                </Grid>
                <Grid item xs={12} sm={4} sx={{textAlign: 'center'}}>
                  <Button ref={ref} variant="contained" size="large" onClick={login} disabled={isWorking || !phoneIsOk()}
                    endIcon={isWorking ? <CircularProgress size={24}/> : <LoginIcon/>}>
                    Let's Go
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Zoom>}
        {!error && callback && <Zoom in={true} style={{
          transitionDelay: `${250}ms`
        }}>
          <Box px={1}>
            <Typography variant="h6" sx={{my: 4}} textAlign="center">
              We just sent a verification code to your phone, please enter it below.
            </Typography>
            <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{textAlign: 'center'}}>
              <Grid item xs={12} sm={8}>
                <TextField name="code" variant="outlined" value={code} onChange={evt => setCode(evt.target.value)}/>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button ref={ref} variant="contained" size="large" onClick={confirm} disabled={isWorking || !code}
                        endIcon={isWorking ? <CircularProgress size={24}/> : <LoginIcon/>}>
                  Verify
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Zoom>}
      </Box>
    </Container>
  )
}

export default Auth;
