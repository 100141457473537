import {Container as MUIContainer} from '@mui/material';

const Container = ({children, sx}) => {
  return (
    <MUIContainer maxWidth="lg" sx={{
      py: {xs: 8, sm: 12, md: 16},
      ...sx
    }}>
      {children}
    </MUIContainer>
  )
}

export default Container;
