import {Alert, AlertTitle, Box} from '@mui/material';

const ErrorAlert = ({error}) => {
  const msg = typeof error === 'string' ? error :
    'message' in error ? error.message : JSON.stringify(error, null, 2);
  return (
    <Alert severity="error">
      <AlertTitle>Oops, an error occurred.</AlertTitle>
      <Box component="pre" sx={{whiteSpace: 'break-spaces'}}>
        {msg}
      </Box>
    </Alert>
  )
}

export default ErrorAlert;
