import {useParams, useNavigate} from 'react-router-dom';
import {
  Button,
  Card, CardActions, CardContent, CardMedia, CircularProgress,
  Container,
  Typography,
} from '@mui/material'
import Page from "./Page";
import {useEffect, useState} from "react";
import {getFunctions, httpsCallable} from "firebase/functions";
import Spinner from "../../comps/Spinner";
import ErrorAlert from "../../comps/ErrorAlert";
import Em from "../../comps/Em";
import {useDispatch, useSelector} from "react-redux";
import {loadCards, stampCard} from "../../state";

const StampPage = () => {
  const {stamp} = useParams();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const cards = useSelector(state => (state.data.cards||[]).filter(c => !c.redeemed));
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isStamping, setStamping] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [cardsLoaded, setCardsLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && cards.length === 0 && !cardsLoaded) {
      dispatch(loadCards())
        .finally(setCardsLoaded(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, cards, cardsLoaded]);

  useEffect(() => {
    httpsCallable(getFunctions(), 'getStamp')({stamp})
      .then(result => {
        if (result.data.status === 'ok') {
          setData(result.data.payload);
        }
        else {
          if (result.data.code === 'not_found') {
            setError(`Sorry, we couldn't find this stamp in our system.`)
          }
          else setError(result.data.payload);
        }
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [stamp]);

  const stampIt = () => {
    setStamping(true);
    dispatch(stampCard({
      cards: cards.map(c => c.id),
      stamp
    }))
      .then(() => navigate('/app', {replace: true}))
      .finally(() => setStamping(false));
  };

  return (
    <Page>
      <Container maxWidth="sm" sx={{
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {isLoading && <Spinner/>}
        {!isLoading && error && <ErrorAlert error={error}/>}
        {!isLoading && !error &&
          <Card sx={{width: '100%'}}>
            <CardMedia
              component="img"
              height="140"
              image={data.image}
              // alt="Sheepdog Brewing Logo"
              sx={{
                objectFit: 'contain',
                p: 1
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Congratulations! You found the <Em>{data.name}</Em> stamp.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="large" variant='contained' onClick={stampIt} disabled={isStamping}
                      endIcon={isStamping ? <CircularProgress size="1rem"/> : null} sx={{width: '100%'}}>
                Stamp It
              </Button>
            </CardActions>
          </Card>
        }
      </Container>
    </Page>
  )
}

export default StampPage;
