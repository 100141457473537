import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, doc, getDoc, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import {login, logout} from "./state";


const app = initializeApp({
  apiKey: "AIzaSyCiZi8HBGtlxzO090tCxm3J-5QaJaml1o4",
  authDomain: "cruise-for-cones.firebaseapp.com",
  projectId: "cruise-for-cones",
  storageBucket: "cruise-for-cones.appspot.com",
  messagingSenderId: "916035202448",
  appId: "1:916035202448:web:1e3929f44eb9b8e0965715"
});

export const AUTH_EMULATOR_URL = "http://localhost:9001";

const useEmulator = true;
export const isUsingEmulator = () => {
  return useEmulator && window.location.hostname === "localhost";
}

const init = store => {
  const auth = getAuth(app);
  const firestore = getFirestore(app);

  if (isUsingEmulator()) {
    console.debug("Connecting to Firebase emulator");
    connectAuthEmulator(auth, AUTH_EMULATOR_URL);
    connectFunctionsEmulator(getFunctions(app), "localhost", 9002);
    connectFirestoreEmulator(firestore, 'localhost', 9003);
    connectStorageEmulator(getStorage(app), "localhost", 9006);
  }

  auth.onAuthStateChanged(user => {
    if (user) {
      user.getIdTokenResult().then((token) => {
        getDoc(doc(firestore,'users', token.claims.phone_number))
          .then(u => {
            let d = {
              ...token,
              id: token.claims.phone_number
            };

            if (u.exists()) {
              d = {
                ...d,
                ...u.data()
              };
            }

            store.dispatch(login(d));
          })
          .catch()
      });
    }
    else {
      store.dispatch(logout({
        init: true
      }));
    }
  });

}
export default init;
