import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import {createCard} from "../../state";


const NewCardModal = ({open, onClose}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [isWorking, setWorking] = useState(false);

  const create = () => {
      setWorking(true);
      dispatch(createCard(name))
        .then(onClose)
        .finally(() => setWorking(false));
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create a Stamp Card</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Let's give your stamp card a name. Using the name of your child is a good way to go in order to manage
          multiple cards.
        </DialogContentText>
        <Box mt={2}>
          <TextField variant="outlined" fullWidth={true} value={name} onChange={evt => setName(evt.target.value)} autoFocus/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!name || isWorking} onClick={create}>
          {isWorking ? <CircularProgress size={24}/> : 'Ok'}
        </Button>
        <Button onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewCardModal;
