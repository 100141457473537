import {Box, Button, Grid, SvgIcon, Typography} from '@mui/material';
import {ReactComponent as Logo} from '../../assets/c4c-logo.svg';
import {ReactComponent as CAMBALogo} from '../../assets/camba-logo.svg';
// import Explode from '../../assets/explode.svg';
import ICBLogo from '../../assets/icb.png';
import SothbysLogo from '../../assets/sothebys.jpg';
import Section from './Section';

const Landing = () => {
  return (
    <Section sx={{textAlign: 'center'}}>
      <Grid container justifyContent="center" alignItems="center" spacing={4} sx={{mb:2}}mv >
        <Grid item>
          <SvgIcon component={CAMBALogo} inheritViewBox sx={{
            width: "100%",
            height: "auto",
            // maxWidth: {
            //   xs: 320,
            //   sm: 512,
            //   md: 728
            // }
          }}/>
        </Grid>
        <Grid item>
          <Typography variant="h4" fontWeight={900} textTransform="uppercase" >Presents</Typography>
        </Grid>
      </Grid>
      <SvgIcon component={Logo} inheritViewBox sx={{
        width: "100%",
        height: "auto",
        maxWidth: {
          xs: 320,
          sm: 512,
          md: 728
        }
      }}/>
      <Typography variant="h4" fontWeight={900} fontStyle="italic" textTransform="uppercase" xcolor="primary" sx={{mt:4}}>
        Brought to you by
      </Typography>
      <Grid container alignItems="center" justifyContent="center" mt={4}>
        <Grid item>
          <Box component="img" src={SothbysLogo} sx={{
            width: {xs: 160, sm: 256}
          }} >
          </Box>
        </Grid>
        <Grid item>
          <Box component="img" src={ICBLogo} sx={{
            width: {xs: 160, sm: 256}, p: {xs: 1, sm: 4}
          }}>
          </Box>
        </Grid>
      </Grid>
      {/*<Grid container alignItems="center" justifyContent="center">*/}
      {/*  <Grid item>*/}
      {/*    <Box sx={{*/}
      {/*      backgroundImage: `url('${Explode}')`,*/}
      {/*      backgroundSize: 'contain',*/}
      {/*      backgroundRepeat: 'no-repeat',*/}
      {/*      backgroundPosition: 'center',*/}
      {/*      px: 4,*/}
      {/*      py: 16*/}
      {/*    }}>*/}
      {/*      <Typography variant="h2" fontFamily="Noot" textTransform="uppercase" color="primary">*/}
      {/*        PRIZES!*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*  </Grid>*/}
      {/*  <Grid item>*/}
      {/*    <Typography variant="h4" fontWeight={900} textTransform="uppercase">*/}
      {/*      Courtesy of*/}
      {/*    </Typography>*/}
      {/*    <Box component="img" src={BikescapeLogo} sx={{width: 196}} >*/}
      {/*    </Box>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
      <Box my={4} display="flex" flexWrap="wrap" justifyContent="center">
        <Typography variant="h3" fontWeight={900} whiteSpace="nowrap">Ride the</Typography>
        <Typography variant="h3" fontFamily="Noot" textTransform="uppercase" whiteSpace="nowrap" color="primary" px={1}>
          Kids Loop
        </Typography>
        <Typography variant="h3" fontWeight={900} whiteSpace="nowrap">to win</Typography>
        <Typography variant="h3" fontFamily="Noot" textTransform="uppercase" whiteSpace="nowrap" color="primary" px={1}>
          Big Prizes
        </Typography>
        <Typography variant="h3" fontWeight={900}>and</Typography>
        <Typography variant="h3" fontFamily="Noot" textTransform="uppercase" whiteSpace="nowrap" color="primary" px={1}>
          Free
        </Typography>
        <Typography variant="h3" fontFamily="Noot" textTransform="uppercase" whiteSpace="nowrap" color="primary" px={1}>
          Ice Cream
        </Typography>
      </Box>
      <Button size="large" variant="contained" href="/app">Connect Now</Button>
    </Section>
  )
}

export default Landing;
