import {useState} from 'react';
import red from '@mui/material/colors/red';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';
import deepPurple from '@mui/material/colors/deepPurple';
import indigo from '@mui/material/colors/indigo';
import blue from '@mui/material/colors/blue';
import cyan from '@mui/material/colors/cyan';
import teal from '@mui/material/colors/teal';
import green from '@mui/material/colors/green';
import lime from '@mui/material/colors/lime';
import orange from '@mui/material/colors/orange';
import deepOrange from '@mui/material/colors/deepOrange';
import brown from '@mui/material/colors/brown';
import blueGrey from '@mui/material/colors/blueGrey';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Zoom
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import pluralize from 'pluralize';
import DeleteCardModal from "../pages/app/DeleteCardModal";
import RedeemCardModal from '../pages/app/RedeemCardModal';


const PALETTE = [red, pink, purple, deepOrange, deepPurple, indigo, blue, cyan, teal, green,
  lime, orange, deepOrange, brown, blueGrey];

const stringToColor = (s) => {
  let h = 0;
  for(let i = 0; i < s.length; i++)
    h = Math.imul(31, h) + s.charCodeAt(i) | 0;

  return PALETTE[Math.abs(h) % PALETTE.length][900];
}

const NUM_STAMPS = 6;
const StampCard = ({card}) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showRedeem, setShowRedeem] = useState(false);
  const numStamps = Object.keys( card.stamps||{}).length;
  const canRedeem = numStamps >= NUM_STAMPS && !card.redeemed;

  return (
    <Card sx={{width: '100%'}}>
      <CardHeader title={card.name}
                  subheader={pluralize('Stamp', Object.keys(card.stamps||{}).length, true)}
                  avatar={<Avatar sx={{
                    backgroundColor: stringToColor(card.name)
                  }}>{card.name.charAt(0).toUpperCase()}</Avatar>}
                  action={<IconButton onClick={() => setShowConfirmDelete(true)}>
                    <DeleteIcon/>
                  </IconButton>}
      />
      <CardContent sx={{backgroundColor: 'grey.100'}}>
        <Grid container spacing={1}>
          {Object.values(card.stamps||{}).map(s => <Grid item key={s.id}>
            <Zoom in={true}>
              <Box component="img" src={s.image} width={64} height={64} sx={{
                border: '2px solid',
                borderColor: 'primary.main',
                borderRadius: '100%',
                padding: 1,
                backgroundColor: 'white'
              }}/>
            </Zoom>
          </Grid>)}
        </Grid>
      </CardContent>
      <CardActions>
        <Box flexGrow={1} mr={1}>
          <LinearProgress variant="determinate" value={Math.min(numStamps/NUM_STAMPS*100,100)} />
        </Box>
        <Button variant="contained" disabled={!canRedeem} onClick={() => setShowRedeem(true)}>Redeem</Button>
      </CardActions>
      <DeleteCardModal card={card} open={showConfirmDelete}
                       onCancel={() => setShowConfirmDelete(false)}
                       onDelete={() => setShowConfirmDelete(false)}/>
      {canRedeem && <RedeemCardModal card={card} open={showRedeem} onClose={() => setShowRedeem(false)}/>}
    </Card>
  )
}

export default StampCard;

