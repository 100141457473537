import {Avatar as MUIAvatar, Box, Button, Paper, styled, useMediaQuery, useTheme} from '@mui/material';

const Avatar = styled(MUIAvatar)(
  ({theme}) => ({
    '& svg': {
      fontSize: '4rem'
    }
  })
);
const Panel = ({title, text, icon, image, button, onDismiss, sx}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper sx={{width: '100%', p:isSmall?2:4, ...sx}}>
      <Box display="flex" flexDirection={isSmall?"column":"row"}>
        {icon && <Avatar sx={{width: '6rem', height: '6rem', backgroundColor: 'primary.main'}} >
          {icon}
        </Avatar>}
        <Box sx={{flexGrow: 1, ml:isSmall?0:2}}>
          {title}
          <Box sx={{mb: 2}}>
            {text}
          </Box>
          <Box display="flex" justifyContent="space-between">
            {button}
            {onDismiss && <Box w="100%" display="flex" justifyContent="flex-start">
              <Button onClick={onDismiss}>Dismiss</Button>
            </Box>}
          </Box>
        </Box>
        {image && <Box component="img" src={image} sx={{
          width: 128,
          objectFit: 'cover',
          border: `4px solid`,
          borderColor: 'primary.main',
          borderRadius: 2,
          m: {xs: 1, sm: 0}
        }}/>}
      </Box>
    </Paper>
  )
}

export default Panel;
