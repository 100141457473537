import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  Box,
  Button,
  Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControlLabel,
  Paper,
  SvgIcon, TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import StartIcon from '@mui/icons-material/Start';
import {ReactComponent as Logo} from '../../assets/c4c-logo-simple.svg';
import Em from '../../comps/Em';
import {setup} from '../../state';
import Container from '../../comps/Container';


const Landing = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [terms, setTerms] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const isReady = terms && !!name.trim() && !!email.trim();
  const go = () => {
    setWorking(true);
    dispatch(setup({name, email}))
      .finally(() => setWorking(false));
  }

  return (
    <Container>
      <Paper sx={{width: '100%', p:isSmall?2:4}}>
        <Box display="flex" flexDirection={isSmall?"column":"row"} alignItems="flex-start">
          <SvgIcon component={Logo} inheritViewBox sx={{width: '6rem', height: 'auto'}}/>
          <Box sx={{flexGrow: 1, ml:isSmall?0:2}}>
            <Typography variant="h5" fontWeight={900} sx={{mt: 1}}>Welcome!</Typography>
            <Box sx={{mb: 2}}>
              <Typography>Thanks for signing up for <Em>Cruise for Cones</Em>. Before you hit the trails, we just need some info, and for you to agree to some terms and conditions for the event.</Typography>
            </Box>
            <Box mt={2}>
              <TextField label="Full Name" value={name} onChange={e => setName(e.target.value)} fullWidth={true}/>
            </Box>
            <Box my={2}>
              <TextField label="Email Address" type="email" value={email} onChange={e => setEmail(e.target.value)} fullWidth={true}/>
            </Box>
            <Button variant="outlined" onClick={() => setShowTerms(true)}>
              Read the Terms
            </Button>
              <FormControlLabel control={<Checkbox value={terms} onChange={e => setTerms(e.target.checked)} sx={{alignSelf: 'flex-start'}}/>}
                                label={<Typography color='text.secondary' variant="subtitle2">By checking this box, <Em>I agree</Em> to the terms and conditions outlined by this Release from Liability and Waiver of all Claims (checked by Parent or Guardian if Participant under 18).</Typography>}
                                sx={{mt: 2}}/>

          </Box>
        </Box>
      </Paper>
      <Box mt={2}>
        <Button variant="contained" size="large" disabled={!isReady || isWorking} onClick={go}
          endIcon={isWorking ? <CircularProgress size={24}/> : <StartIcon/>}>
          Let's Go!
        </Button>
      </Box>
      {showTerms && <Dialog
        open={showTerms}
        onClose={() => setShowTerms(false)}
        maxWidth="md"
        PaperProps={{
          sx: {margin: 1, marginTop: 0}
        }}
      >
        <DialogTitle>RELEASE FROM LIABILITY AND WAIVER OF ALL CLAIMS</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{mb:1}}>
            In consideration of Canmore and Area Mountain Bike Association and Wolfe Canmore ("the Organizers"), collectively accepting my application and my being permitted to participate in an online-based fundraiser known as the "Cruise for Cones" (herein called the "Fundraiser") I agree to this release of claims, waiver of liability and assumption of risks (collectively this "Agreement").
          </DialogContentText>
          <DialogContentText sx={{mb:1}}>
            I waive any and all claims I may have against, and release from all liability and agree not to sue the Organizers for any personal injury, death, property damage or loss sustained by me as a result of my participation in the Fundraiser due to any cause whatsoever including, without limitation: negligence, including failure by the Organizers to use such care as a reasonably prudent and careful guide or event organizer would use under similar circumstances, breach of contract, or breach of any statutory or other duty of care, including any duty of care owed under any applicable Occupier’s Liability legislation on the part of the Organizers, and further including any failure on the part of any Organizer to take reasonable steps to safeguard or protect me from the risks, dangers and hazards of participating in the Fundraiser.
          </DialogContentText>
          <DialogContentText>
            I am aware that participating in the Fundraiser presents dangers and risks, some of which include, but are not limited to:
            <ul>
              <li>Motor Traffic: including negligence of drivers;</li>
              <li>Natural Hazards: including those natural hazards regularly encountered when cycling
                off-road; and</li>
              <li>Conduct of other participants: conduct of other cyclists, whether participating in the
                Fundraiser or not.</li>
            </ul>
          </DialogContentText>
          <DialogContentText sx={{mb:1}}>
            I willingly accept all the risks of participating in the Fundraiser, including without limitation the risks described above and the possibility of personal injury, death, property loss or loss resulting therefrom. I acknowledge that injury resulting from these or other risks may be exacerbated by my failure to wear a properly fitting helmet. I acknowledge that the Organizers strongly recommend the use of a helmet for any cycling activity, including the Fundraiser. In entering into this Agreement I am not relying on any oral or written representations or statements made by any Organizer. I acknowledge that the Organizers urge every participant to ride safely and with proper trail etiquette during any Fundraiser.
          </DialogContentText>
          <DialogContentText sx={{mb:1}}>
            I confirm that I am of the Age of Majority (full age of eighteen years) and that I have read and understood this Agreement. I agree that this Agreement shall be effective and binding upon my heirs, next of kin, executors, administrators, assigns and representatives, in the event of my death, and that this Agreement shall be governed by and interpreted in accordance with the laws of the Province of Alberta, and that any litigation involving the parties to this Agreement shall be brought within the Province of Alberta.
          </DialogContentText>
          <DialogContentText sx={{mb:1}}>
            I acknowledge that this Agreement is supplemental to and does not replace any other waiver, indemnity, or other agreement I may have executed in favor of any of the Organizers, their agents or assigns.
          </DialogContentText>
          <DialogContentText>
            <Em>I acknowledge that by accepting these terms I am giving up the right to sue.</Em>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTerms(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>}
    </Container>
  )
}

export default Landing;
