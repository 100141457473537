import {useSelector} from "react-redux";
import {Box} from "@mui/material";
import Spinner from "../../comps/Spinner";
import Auth from "./Auth";
import Landing from "./Landing";

const Page = ({children}) => {
  const isInit = useSelector(state => state.init === true && state.work !== true);
  const isLoggedIn = useSelector(state => state.user != null);
  const isSetup = useSelector(state => (state.user||{}).waiver === true);

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
      {!isInit && <Spinner centered={true}/>}
      {isInit && !isLoggedIn && <Auth/>}
      {isInit && isLoggedIn && !isSetup && <Landing/>}
      {isInit && isLoggedIn && isSetup && <>
        {children}
      </>}
    </Box>
  )
}

export default Page;
