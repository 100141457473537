import {useState} from 'react';
import {getAuth, signOut} from 'firebase/auth';
import {Avatar as MUIAvatar, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const Avatar = ({user, sx}) => {
  const [menu, setMenu] = useState();
  const isMenuOpen = Boolean(menu);

  const logout = () => {
    signOut(getAuth());
  }

  return (
    <>
      <MUIAvatar sx={{
        cursor:  'pointer',
        ...sx
      }} onClick={e => setMenu(e.currentTarget)}>{user.name.toUpperCase().charAt(0)}</MUIAvatar>
      <Menu open={isMenuOpen} anchorEl={menu} onClose={() => setMenu(null)}>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default Avatar;
