import {Box, Button, Stack, Typography} from '@mui/material'
import Section from './Section'

const Step = ({number, title, description, action}) => {
  return (
    <Box display="flex" mt={4}>
      <Typography fontWeight={900} sx={{
        flex: '1 0 auto',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: {xs: '1.5rem', sm:'2.25rem'},
        width: {xs: '3rem', sm:'4rem'},
        height: {xs: '3rem', sm:'4rem'},
        // border: `4px solid`,
        // borderColor: 'primary.main',
        color: 'white',
        bgcolor: 'primary.main',
        borderRadius: '100%'
      }}>{number}</Typography>
      <Stack pl={2} gap={1} sx={{
          flexDirection: {xs: 'column', sm: 'row'}
      }}>
          <Stack>
              <Typography variant="h5" fontWeight="bold" >{title}</Typography>
              <Typography variant="body1" sx={{
                  fontSize: '1.5rem',
              }}>{description}</Typography>
          </Stack>
          {action && <Button elevation={0} variant="contained" href={action.href} sx={{
              width: {xs: '100%', sm:'196px'}, flexShrink:0, textAlign: 'center'
          }}>
              {action.title}
          </Button>}

      </Stack>
    </Box>
  )
}

const HowItWorks = () => {
  return (
    <Section>
      <Typography fontWeight={900} color="primary" textTransform="uppercase" textAlign="center" sx={{
        fontSize: {xs: '3rem', md: '3.75rem'},
      }}>
        How C4C Works
      </Typography>
      <Step number={1} title="Connect to the C4C App"
            description="Getting started is super easy. Head on over to the C4C app and you'll be cruising in minutes. All you need is a phone number."
            action={{title: 'Connect to the App', href: '/app'}}/>
      <Step number={2} title="Hit the Trails"
            description="Head out with your little grom and ride the Fun Forest and Mosquito Bite trails. We've mapped it out for you with a map on Trailforks if you're not familiar with those trails."
            action={{title: 'Connect to the Map', href: '#trails'}}/>
      <Step number={3} title="Scan the Cones"
            description="Along the route you'll find orange cones with a QR code on them. Scan them to build up a stamp card. Get 7 out of the 10 stamps and you've earned yourself an ice cream from the Old School Ice Cream Bus."/>
    </Section>
  )
}

export default HowItWorks;
