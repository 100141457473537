import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import pluralize from "pluralize";
import {
  Box,
  Button, Fab,
  Grid,
  Typography
} from '@mui/material';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import AddIcon from '@mui/icons-material/Add';
import AddCardIcon from '@mui/icons-material/AddCard';
import Container from '../../comps/Container';
import Panel from '../../comps/Panel';
import StampCard from '../../comps/StampCard';
import NewCardModal from './NewCardModal';
import Header from './Header';
import {loadCards} from '../../state';
import Heading from "../../comps/Heading";
import {useLocalStorage} from './useLocalStorage';
// import ConeSticker from '../../assets/cone-sticker.jpg'


const Dash = () => {
  const dispatch = useDispatch();
  const cards = useSelector(state => state.data.cards||[]);
  const [newCard, setNewCard] = useState(false);
  const [showHelp ,setShowHelp] = useLocalStorage('c4c.showHelp',true);

  useEffect(() => {
    dispatch(loadCards());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="100%">
      <Header/>
      <Container>
        {cards.length === 0 &&
        <Panel icon={<AddCardIcon/>}
               title={<Typography variant="h4" fontWeight="bold" color="primary" gutterBottom>Create your Cards</Typography>}
               text={<Typography variant="h6" color="text.secondary">
                 The first step is to create your stamp cards. You can create multiple cards ,one for each little grom you have with
                 you out on the trail. You can also use the plus button <Fab color="primary" size="small" onClick={() => setNewCard(true)}>
                 <AddIcon />
               </Fab> in the upper right corner at any time to create a new card.
               </Typography>}
               button={<Button variant="outlined" onClick={() => setNewCard(true)}>Create a Stamp Card</Button> }
        >

        </Panel>}
        {cards.length > 0 &&
          <>
            {showHelp && <Panel icon={<DirectionsBikeIcon/>} sx={{mb: 2}}
                   title={<Typography variant="h4" fontWeight="bold" color="primary" gutterBottom>Time to Cruise!</Typography>}
                   text={<Typography variant="h6" color="text.secondary">
                     Your next step is to head out pedal one of the trails that are part of the fundraiser. When you see a cone,
                     scan the QR code with this device and it will stamp your card(s). Get 6 out of the 10 cards and get a free ice
                     cream cone!
                   </Typography>}
                   button={<Button variant="outlined" href="/#trails">Show me the Trails</Button> }
                   onDismiss={() => setShowHelp(false)}
            />}
            <Heading title={pluralize('Stamp Card', cards.length, true)}/>
            <Grid container spacing={1}>
              {cards.map(c => <Grid key={c.id} item xs={12} sm={6}>
                <StampCard card={c}/>
              </Grid>)}
            </Grid>
          </>
        }
        <NewCardModal open={newCard}
                      onClose={() => setNewCard(false)}/>
      </Container>
    </Box>
  )
}

export default Dash;
