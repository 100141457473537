import Page from "./Page";
import Dash from "./Dash";

const AppPage = () => {
  return (
    <Page>
      <Dash/>
    </Page>
  )
}

export default AppPage;
