import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {deleteCard} from '../../state';


const DeleteCardModal = ({card, open, onDelete, onCancel}) => {
  const dispatch = useDispatch();
  const [isWorking, setWorking] = useState(false);

  const doDelete = () => {
    setWorking(true);
    dispatch(deleteCard(card))
      .then(onDelete)
      .finally(() => setWorking(false))
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Delete Stamp Card</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the card named {card.name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={doDelete} disabled={isWorking}>
          {isWorking ? <CircularProgress size={24}/> : 'Ok'}
        </Button>
        <Button onClick={onCancel}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteCardModal;
