import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk'
import {createAction, createAsyncThunk, createReducer} from '@reduxjs/toolkit';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getFirestore, collection, getDocs, query, orderBy} from 'firebase/firestore';

const mapCard = doc => {
  return {id: doc.ref.id, ...doc.data()}
}

export const login = createAction('login');
export const logout = createAction('logout');

export const setup = createAsyncThunk(
  'setup',
  (args, {rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'setup')({
      waiver: true,
      ...args
    }).then(result => {
      if (result.data.status === 'ok') {
        return result.data.payload;
      }
      return rejectWithValue(result.data);
    });
  }
)

export const createCard = createAsyncThunk(
  'cards/create',
  (name, {getState, rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'createCard')({name}).then(result => {
      if (result.data.status === 'ok') {
        return result.data.payload;
      }
      return rejectWithValue(result.data);
    }).catch(rejectWithValue)
  }
)

export const deleteCard = createAsyncThunk(
  'cards/delete',
  (card, {getState, rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'deleteCard')({card: card.id}).then(result => {
      if (result.data.status === 'ok') {
        return result.data.payload;
      }
      return rejectWithValue(result.data);
    }).catch(rejectWithValue)
  }
)

export const loadCards = createAsyncThunk(
  'cards/load',
  (arg, {getState, rejectWithValue}) => {
    return getDocs(query(
      collection(getFirestore(), 'users', getState().user.id, 'cards'),
      orderBy('created', 'desc')
    )).then(result => {
      return result.docs.map(mapCard);
    }).catch(rejectWithValue)
  }
)

export const stampCard = createAsyncThunk(
  'cards/stamp',
  (args, {getState, rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'stampCard')(args)
      .then(result => {
        if (result.data.status === 'ok') {
          return result.data.payload;
        }
        else {
          rejectWithValue(result.data);
        }
      })
      .catch(rejectWithValue)
  }
)
const reducer = createReducer({
  init: false,
  user: null,
  data: {},
}, builder => {
  builder
    .addCase(login, (state, action) => {
      state.user = action.payload;
      state.init = true;
    })
    .addCase(logout, (state, action) => {
      state.user = null;
      state.init = true;
    })
    .addCase(setup.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload
      }
    })
    .addCase(loadCards.fulfilled, (state, action) => {
      state.data.cards = action.payload;
    })
    .addCase(createCard.fulfilled, (state, action) => {
      state.data.cards = [...(state.data.cards||[]), action.payload];
    })
    .addCase(deleteCard.fulfilled, (state, action) => {
      state.data.cards = (state.data.cards||[]).filter(c => c.id !== action.payload);
    })
    .addCase(stampCard.fulfilled, (state, action) => {
      state.data.cards = [...action.payload];
    })
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);


