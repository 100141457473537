import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom'
import {
  AppBar, Fab, Grid,
  SvgIcon,
  Toolbar, Tooltip,
  useTheme,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import {ReactComponent as Logo} from '../../assets/c4c-logo-simple.svg';
import NewCardModal from './NewCardModal';
import Avatar from "./Avatar";


const Header = () => {
  const theme = useTheme();
  const user = useSelector(state => state.user);
  const [newCard, setNewCard] = useState(false);

  return (
    <AppBar position="static" color="secondary">
      <Toolbar sx={{justifyContent: 'space-between'}}>
        <Link to="/app">
          <SvgIcon component={Logo} inheritViewBox sx={{
            color: 'initial',
            width: {xs: '4rem', md: '6rem'},
            height: "auto",
            padding: theme.spacing(1),
            // mr: isSmall?0:2
          }}/>
        </Link>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Tooltip title="Add a New Stamp Card">
              <Fab color="primary" onClick={() => setNewCard(true)}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
          <Grid item>
            {user && <Avatar user={user} sx={{ width: 56, height: 56 }}/>}
          </Grid>
        </Grid>
      </Toolbar>
      <NewCardModal open={newCard} onClose={() => setNewCard(false)} />
    </AppBar>
  )
}

export default Header;
